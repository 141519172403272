<template>
  <div class="mt-1" v-if="canVerb(resource, 'index')">
    <b-row>
      <b-col align="left">
        <b-button-group
          v-b-toggle:collapse-1
          class="my-2 filter-button-group"
          :title="getDefaultFilterMessage()"
        >
          <span class="when-open">
            <b-icon icon="funnel" font-scale="1.5"></b-icon>
          </span>
          <span class="when-closed">
            <b-icon icon="funnel" font-scale="1.5"></b-icon>
          </span>
          Filtra
        </b-button-group>
      </b-col>
      <b-col align="right">
        <b-button
          class="btn-create"
          type="button"
          variant="primary"
          size="sm"
          @click="onAdd"
          title="Crea"
          v-if="canVerb(resource, 'store')"
          ><b-icon icon="plus-circle" aria-hidden="true"></b-icon>
          Crea</b-button
        >
      </b-col>
    </b-row>

    <b-collapse visible id="collapse-1">
      <b-form @submit.prevent="onSearch(filterName)">
        <b-card class="filter">
          <b-row>
            <div class="col-md-3">
              <base-input
                name="number"
                type="text"
                label="Numero Polizza"
                v-model="filter.byCustom.number.value"
                placeholder="Inserisci un numero polizza"
                @input="onInputNumberOrRegistry"
              />
            </div>
            <div class="col-md-3">
              <div>
                <b-button-toolbar>
                  <b-button-group>
                    <base-input
                      name="Anagrafica"
                      label="Anagrafica"
                      v-model="form.inpt_label_registry"
                      :readonly="true"
                    />
                    <b-button
                      class="mt-4 btn-quick"
                      size="sm"
                      text="Button"
                      variant="lisaweb"
                      title="Ricerca Veloce"
                      @click="openQuickSearchRegistry"
                      ><b-icon icon="search"></b-icon
                    ></b-button>
                    <b-button
                      v-if="form.inpt_label_registry"
                      class="mt-4 btn-quick ml-1"
                      size="sm"
                      text="Button"
                      variant="lisaweb"
                      @click="resetRegistryId"
                      title="Elimina Anagrafica"
                      ><b-icon icon="trash"></b-icon
                    ></b-button>
                  </b-button-group>
                </b-button-toolbar>
              </div>
            </div>
            <quick-search-registry-modal
              ref="quickSearchRegistry"
              @input="handleRegistryModalInput"
            >
            </quick-search-registry-modal>
            <div class="form-group col-md-3 quick-search-result">
              <span
                v-if="registry_data"
                class="info"
                v-html="toInfoData(registry_data, 'registry')"
              >
              </span>
            </div>
            <!-- <div class="form-group col-md-3">
              <div>
                <b-button-toolbar>
                  <b-button-group>
                    <base-input
                      name="Polizza"
                      label="Polizza"
                      v-model="form.inpt_label_policy"
                      :readonly="true"
                    />
                    <b-button
                      class="mt-4 btn-quick"
                      size="sm"
                      text="Button"
                      variant="lisaweb"
                      title="Ricerca Veloce"
                      @click="openQuickSearchPolicy"
                      ><b-icon icon="search"></b-icon
                    ></b-button>
                    <b-button
                      class="mt-4 btn-quick ml-1"
                      size="sm"
                      text="Button"
                      variant="lisaweb"
                      @click="resetPolicyId"
                      title="Elimina Polizza"
                      ><b-icon icon="trash"></b-icon
                    ></b-button>
                  </b-button-group>
                </b-button-toolbar>
              </div>
            </div>
            <quick-search-policy-modal
              ref="quickSearchPolicy"
              @input="handlePolicyModalInput"
            >
            </quick-search-policy-modal>
            <div class="form-group col-md-3">
              <span
                v-if="insurance_policy_data"
                class="info"
                v-html="
                  toInfoData(insurance_policy_data, 'insurance_policy', 0)
                "
              >
              </span>
            </div> -->
          </b-row>
          <b-row>
            <div class="col-md-3">
              <base-datepicker
                name="book_date"
                label="Data Registrazione Dal"
                v-model="filter.byCalendar.from"
                :disabled="isFilterLoading"
              />
            </div>
            <div class="col-md-3">
              <base-datepicker
                name="book_date"
                label="Data Registrazione al"
                v-model="filter.byCalendar.to"
                :disabled="isFilterLoading"
              />
            </div>
            <div class="col-md-3">
              <base-datepicker
                name="effective_date"
                label="Data Effetto Dal"
                v-model="filter.byEffectiveness.from"
              />
            </div>
            <div class="col-md-3">
              <base-datepicker
                name="effective_date"
                label="Data Effetto al"
                v-model="filter.byEffectiveness.to"
              />
            </div>
          </b-row>
          <b-row>
            <div class="col-md-3">
              <base-select
                name="insurer_id"
                label="Compagnia"
                v-model="filter.byInsurer.id"
                :options="companies"
                :taggable="true"
                :multiple="true"
                @input="onInputInsurer"
              />
            </div>
            <div class="col-md-3">
              <base-select
                name="Produttore"
                label="Produttore"
                v-model="filter.byBrokerEnhanced.salesmen"
                :options="salesmen"
                :taggable="salesmen_taggable"
                :multiple="true"
                :closeOnSelect="false"
                :defaultHtmlMultipleSelect="true"
                @changeTaggable="changeTaggable"
              />
            </div>
            <div class="col-md-3">
              <base-select
                name="Ramo"
                label="Ramo"
                :options="risk_branches"
                v-model="filter.byRiskBranch.id"
                :multiple="true"
                :taggable="true"
                :closeOnSelect="false"
                @input="onInputBranch"
              />
            </div>
            <div class="col-md-3">
              <base-select
                name="Prodotto"
                label="Prodotto"
                :options="products"
                v-model="filter.byInsuranceRisk.id"
                :taggable="true"
                :multiple="true"
                :closeOnSelect="false"
              />
            </div>
          </b-row>
          <b-row>
            <div class="col-md-3">
              <base-select
                name="broker_statement"
                label="Stato Rendicontale"
                v-model="filter.byBrokerStatement.id"
                :options="[
                  { value: '!0', text: 'Rendicontato' },
                  { value: '!', text: 'Non rendicontato' },
                ]"
                :taggable="false"
                :multiple="false"
                :closeOnSelect="true"
              />
            </div>
            <div class="form-group col-md-3">
              <div>
                <b-button-toolbar>
                  <b-button-group>
                    <base-input
                      name="Gruppo Anagrafico"
                      label="Gruppo Anagrafico"
                      v-model="form.inpt_label_group"
                      :readonly="true"
                    />
                    <b-button
                      class="mt-4 btn-quick"
                      size="sm"
                      text="Button"
                      variant="lisaweb"
                      title="Ricerca Veloce"
                      @click="openQuickSearchGroup"
                      ><b-icon icon="search"></b-icon
                    ></b-button>
                    <b-button
                      v-if="form.inpt_label_group"
                      class="mt-4 btn-quick ml-1"
                      size="sm"
                      text="Button"
                      variant="lisaweb"
                      @click="resetGroupId"
                      title="Elimina Gruppo"
                      ><b-icon icon="trash"></b-icon
                    ></b-button>
                  </b-button-group>
                </b-button-toolbar>
              </div>
            </div>
            <quick-search-group-modal
              ref="quickSearchGroup"
              @input="handleGroupModalInput"
            ></quick-search-group-modal>
            <div class="form-group col-md-3">
              <span
                v-if="group_data"
                class="info"
                v-html="toInfoData(group_data, 'registry_group_full')"
              >
              </span>
            </div>
          </b-row>

          <b-button-group
            v-b-toggle:collapse-1-inner
            class="mt-2 mb-4 filter-button-group"
          >
            <span class="when-open">
              <b-icon icon="funnel" font-scale="1.5"></b-icon>
            </span>
            <span class="when-closed">
              <b-icon icon="funnel" font-scale="1.5"></b-icon>
            </span>
            Altri Filtri
          </b-button-group>

          <b-collapse id="collapse-1-inner" class="">
            <b-row>
              <div class="col-md-3">
                <base-select
                  name="cooperators"
                  label="Collaborazioni"
                  v-model="filter.byBrokerEnhanced.cooperators"
                  :options="cooperators"
                  :taggable="true"
                  :multiple="true"
                  :closeOnSelect="false"
                />
              </div>
              <div class="col-md-3">
                <base-select
                  name="treasury_id"
                  label="Forme di Pagamento"
                  v-model="filter.byTreasury.id"
                  :options="treasuries"
                />
              </div>
              <div class="col-md-3">
                <base-select
                  name="book_tags"
                  label="Codice Documento"
                  v-model="filter.byBookTag.id"
                  :options="book_tags_opt"
                />
              </div>
            </b-row>
            <b-row>
              <div class="col-md-3">
                <base-input
                  name="note"
                  type="text"
                  label="Nota"
                  v-model="filter.byTask.description"
                  placeholder="Inserisci una nota"
                />
              </div>
              <div class="col-md-3">
                <base-input
                  name="REPL"
                  type="text"
                  label="Targa"
                  v-model="filter.byInsurancePolicy.REPL"
                  placeholder="Inserisci una targa"
                />
              </div>
              <div class="col-md-3">
                <base-datepicker
                  name="imported_at"
                  label="Data Foglio Cassa"
                  v-model="filter.byAttribute.imported_at"
                />
              </div>
              <div class="col-md-3">
                <base-datepicker
                  name="sheet_date"
                  label="Data Importazione"
                  v-model="filter.byAttribute.sheet_date"
                />
              </div>
            </b-row>
            <b-row>
              <div class="col-md-3">
                <base-currency
                  name="gross"
                  label="Premio lordo da"
                  v-model="filter.byCurrency.from"
                  :options="{ currency: 'EUR', locale: 'it-IT' }"
                  placeholder="Inserisci un premio lordo"
                />
              </div>
              <div class="col-md-3">
                <base-currency
                  name="gross"
                  label="Premio lordo a"
                  v-model="filter.byCurrency.to"
                  :options="{ currency: 'EUR', locale: 'it-IT' }"
                  placeholder="Inserisci un premio lordo"
                />
              </div>
              <div class="col-md-3">
                <base-currency
                  name="net"
                  label="Premio netto da"
                  v-model="filter.byNet.from"
                  :options="{ currency: 'EUR', locale: 'it-IT' }"
                  placeholder="Inserisci un premio netto"
                />
              </div>
              <div class="col-md-3">
                <base-currency
                  name="net"
                  label="Premio netto a"
                  v-model="filter.byNet.to"
                  :options="{ currency: 'EUR', locale: 'it-IT' }"
                  placeholder="Inserisci un premio netto"
                />
              </div>
            </b-row>
            <div v-show="customFilters.length > 0">
              <p>Attributi personalizzati</p>

              <b-row v-for="(element, index) in customFilters" :key="index">
                <custom-filter
                  v-model="filter.byAttribute[element.key]"
                  :element="element"
                />
              </b-row>
            </div>
          </b-collapse>

          <b-row>
            <div class="form-group col-md-3 align-self-end">
              <b-button
                type="submit"
                variant="lisaweb"
                size="sm"
                v-b-toggle:collapse-1
                >Cerca</b-button
              >
              <b-button
                class="btn-reset"
                type="button"
                variant="lisaweb"
                size="sm"
                @click="onClearFilter(filterName)"
                >Reset</b-button
              >
            </div>
          </b-row>
        </b-card>
      </b-form>
    </b-collapse>

    <export-options
      :exportUrl="exportUrl"
      :repository="repository"
      :resource="resource"
      :tableRef="$refs[tableRef]"
      :filter="filter"
      :options="[
        {
          code: 'EXPOBOEN',
          label: null,
          formats: ['csv', 'pdf'],
        },
        {
          code: 'REGIFOCA',
          label: null,
          formats: ['csv', 'pdf'],
        },
        // {
        //   code: 'INCACOMP2',
        //   label: null,
        //   formats: ['pdf'],
        // },
        {
          code: 'TOTAFOCA',
          label: null,
          formats: ['pdf', 'csv'],
        },
        {
          code: 'FOCACOL',
          label: null,
          formats: ['pdf', 'csv'],
        },
        {
          code: 'FOCALETACC',
          label: null,
          formats: ['pdf', 'csv'],
        },
      ]"
    ></export-options>

    <!-- <button-group-table
      @csv="onExport('csv')"
      @pdf="openReportModal('INCACOMP2')"
      :options="{
        CSV: { enabled: true },
        PDF: { enabled: true },
      }"
    ></button-group-table> -->
    <!-- REVIEW story-36-a: enable this when BE can handle sort requests!!! -->
    <!-- sortField="book_date" -->
    <book-entries
      @edit="onEdit"
      @destroy="onDestroy"
      :fields="fields"
      :repository="repository"
      :resource="resource"
      :filterOn="{
        byRelations: [
          'byBroker',
          'byDocument',
          'byTask',
          'byRegistry',
          'byInsurer',
          'byInsuranceAncillary',
          'byVariousAccounting',
          'byBookTag',
          'byInsurerAccounting',
          'byBreakdown',
          'byInsurerParticipant',
          'byBrokerStatement',
        ],
      }"
      :filterName="filterName"
      :ref="tableRef"
      :hasChecksButtonGroup="false"
      :onlyActions="['infomodal', 'destroy', 'edit']"
      sortField="book_date"
    ></book-entries>
  </div>
  <div v-else class="mt-3">
    <b-icon icon="info-circle" scale="1.00"></b-icon>
    Non hai i permessi
  </div>
</template>

<script>
import BookEntries from "@/components/tables/BookEntries";
import BaseCurrency from "@/components/form/BaseCurrency";
import BaseInput from "@/components/form/BaseInput";
// import ButtonGroupTable from "@/components/ButtonGroupTable";
import ExportOptions from "@/components/ExportOptions";
import BaseSelect from "@/components/form/BaseSelect";
import BaseDatepicker from "@/components/form/BaseDatepicker";
import CustomFilter from "@/components/form/CustomFilter";
import CustomFiltersMixin from "@/mixins/CustomFiltersMixin";
import StorageGetterMixin from "@/mixins/StorageGetterMixin";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import { mapGetters, mapActions } from "vuex";
import QuickSearchRegistryModal from "@/components/modals/quickSearchRegistry";
import QuickSearchGroupModal from "@/components/modals/quickSearchGroup";
import { toLocaleCurrency } from "@/utils/strings";
import { toLocaleDate } from "@/utils/dates";
import { toInfoData } from "@/utils/transforms";
// import ExportsMixin from "@/mixins/ExportsMixin";
// import submitReportModal from "@/components/modals/submitReportModal";
// import ReportsMixin from "@/mixins/ReportsMixin";

export default {
  mixins: [
    CustomFiltersMixin,
    StorageGetterMixin /* , ExportsMixin, ReportsMixin */,
  ],
  data() {
    return {
      exportUrl: null,
      filter: this.initFilter(),
      isFilterLoading: false, // use when there are default values coming from api call
      filterName: "filterBookEntry",
      resource: "book_entries",
      repository: "book_entry",
      tableRef: "BookEntryTableRef",
      salesmen: [],
      salesmen_taggable: true,
      cooperators: [],
      risk_branches: [],
      companies: [],
      products: [],
      risks: [],
      treasuries: [],
      book_tags_opt: [],
      notes: [],
      documents: [],
      registry_data: null,
      group_data: null,
      // insurance_policy_data: null,
      form: {
        // inpt_label_policy: "",
        inpt_label_registry: "",
        inpt_label_group: "",
        quick_value: "",
      },
      fields: [
        {
          key: "book_date",
          label: this.getDictionary("book_date", "book_entry"),
          sortable: true,
          formatter: (value) => toLocaleDate(value),
          sortKey: "book_date",
        },
        {
          key: "effective_date",
          label: this.getDictionary("effective_date", "book_entry"),
          sortable: true,
          formatter: (value) => toLocaleDate(value),
          sortKey: "effective_date",
        },
        {
          key: "document_code",
          label: this.getDictionary("code", "book_tag"),
        },
        {
          key: "insurer.display",
          label: this.getDictionary("insurer_id", "book_entry"),
          sortable: true,
          sortKey: "byInsurer.display",
        },
        {
          key: "branch",
          label: this.getDictionary("title", "risk_branch"),
          sortable: true,
          sortKey: "byRiskBranch.title",
        },
        {
          key: "product",
          label: this.getDictionary("insurance_risk"),
          sortable: true,
          sortKey: "byInsuranceRisk.title",
        },
        {
          key: "insurance_policy_number",
          label: this.getDictionary("insurance_policy_number", "book_entry"),
          sortable: true,
          sortKey: "byInsurancePolicy.number",
        },
        {
          key: "customer",
          label: this.getDictionary("registry", "book_entry"),
          sortable: true,
          sortKey: "byRegistry.title",
        },
        {
          key: "gross",
          label: this.getDictionary("gross", "book_entry"),
          formatter: (value) => toLocaleCurrency(value),
          tdClass: "text-right",
          thClass: "text-right",
          sortable: true,
        },
        {
          key: "agency_gross",
          label: this.getDictionary("agency_gross"),
          tdClass: "text-right",
          thClass: "text-right",
        },
        {
          key: "saler_gross",
          label: this.getDictionary("saler_gross"),
          tdClass: "text-right",
          thClass: "text-right",
        },
        {
          key: "cooperator",
          label: this.getDictionary("cooperator"),
          sortable: true,
          sortKey: "byBroker.BUID",
        },
        {
          key: "broker",
          label: this.getDictionary("broker", "book_entry"),
          sortable: true,
          sortKey: "byBroker.BUID",
        },
        {
          key: "note_counter",
          label: this.getDictionary("note"),
        },
        {
          key: "document_counter",
          label: this.getDictionary("document"),
        },
        {
          key: "broker_statement_id",
          label: this.getDictionary("broker_statement_id"),
        },
      ],
    };
  },
  components: {
    BaseInput,
    // ButtonGroupTable,
    ExportOptions,
    BaseSelect,
    BaseDatepicker,
    BookEntries,
    BaseCurrency,
    CustomFilter,
    QuickSearchRegistryModal,
    QuickSearchGroupModal,
    // submitReportModal,
  },
  methods: {
    toInfoData,
    changeTaggable(val) {
      this.salesmen_taggable = val;
    },
    initFilter() {
      let init = {
        byAttribute: {
          title: null,
          year: null,
          contractor: null,
          book_date: null,
          sheet_date: null,
          gross: null,
        },
        byView: "book_entry",
        byCalendar: {
          from: null,
          to: null,
        },
        byEffectiveness: {
          from: null,
          to: null,
        },
        byTreasury: {
          id: null,
        },
        byBookTag: {
          id: null,
        },
        byBrokerEnhanced: {
          salesmen: [],
          cooperators: [],
        },
        byRegistry: {
          id: null,
        },
        byCustom: {
          number: {
            value: null,
            likewise: 0,
            filter: "byInsurancePolicy",
          },
        },
        byRegistryGroup: {
          code: null,
        },
        byInsurer: {
          id: null,
        },
        byCurrency: {
          to: null,
          from: null,
        },
        byNet: {
          from: null,
          to: null,
        },
        byInsuranceRisk: {
          id: null,
        },
        byRiskBranch: {
          id: null,
        },
        byRiskType: {
          id: null,
        },
        byBrokerStatement: {
          id: null,
        },
        byInsurancePolicy: {
          REPL: null,
        },
        byTask: {
          description: null,
        },
      };
      return init;
    },
    onInputNumberOrRegistry() {
      this.filter.byCalendar.from =
        this.filter.byCalendar.from &&
        this.filter.byCalendar.from != this.lastCreated
          ? this.filter.byCalendar.from
          : this.filter.byCustom.number.value || this.form.inpt_label_registry
          ? null
          : this.lastCreated;
      this.filter.byCalendar.to =
        this.filter.byCalendar.to &&
        this.filter.byCalendar.to != this.lastCreated
          ? this.filter.byCalendar.to
          : this.filter.byCustom.number.value || this.form.inpt_label_registry
          ? null
          : this.lastCreated;
    },
    onInputInsurer(insurer) {
      this.filter.byInsurer.id = insurer;
      this.filter.byRiskBranch.id = null;
      this.risk_branches = this.getBranches()(insurer);
      this.filter.byInsuranceRisk.id = null;
      this.products = this.getProducts()(
        this.filter.byInsurer.id,
        this.filter.byRiskBranch.id,
        this.filter.byRiskType.id
      );
    },
    onInputBranch(branches) {
      if (!branches.length) {
        this.filter.byRiskBranch.id = null;
      }
      this.filter.byInsuranceRisk.id = null;
      this.products = this.getProducts()(
        this.filter.byInsurer.id,
        branches,
        this.filter.byRiskType.id
      );
    },
    onInputRisk(risks) {
      if (!risks.length) {
        this.filter.byRiskType.id = null;
      }
      this.filter.byInsuranceRisk.id = null;
      this.products = this.getProducts()(
        this.filter.byInsurer.id,
        this.filter.byRiskBranch.id,
        risks
      );
    },
    handleRegistryModalInput(value) {
      this.filter.byRegistry.id = value.id;
      this.registry_data = value;
      this.form.inpt_label_registry = this.toInfoData(
        this.registry_data,
        "registryfullname"
      );
      this.onInputNumberOrRegistry();
      this.$bvModal.hide("quickSearchRegistryModal");
    },
    openQuickSearchRegistry() {
      this.filter.byRegistry.id = null;
      this.$bvModal.show("quickSearchRegistryModal");
    },
    resetRegistryId() {
      this.registry_data = null;
      this.form.inpt_label_registry = null;
      this.filter.byRegistry.id = null;
      this.onInputNumberOrRegistry();
    },
    handleGroupModalInput(value) {
      this.filter.byRegistryGroup.code = value.code;
      this.group_data = value;
      this.form.inpt_label_group = this.toInfoData(
        this.group_data,
        "registry_group"
      );
      this.$bvModal.hide("quickSearchGroupModal");
    },
    openQuickSearchGroup() {
      this.filter.byRegistryGroup.code = null;
      this.$bvModal.show("quickSearchGroupModal");
    },
    resetGroupId() {
      this.group_data = null;
      this.form.inpt_label_group = null;
      this.filter.byRegistryGroup.code = null;
    },
    onAdd() {
      this.$router.push({
        name: `${this.resource}.create`,
        params: { id: null, item: null, type: "create" },
      });
    },
    customFilterName(key, prefix = "byAttribute") {
      return `${prefix}.${key}`;
    },
    onEdit(item) {
      this.$router.push({
        name: `${this.resource}.edit`,
        params: { id: `${item.id}`, item: item, mode: "I", type: "edit" },
      });
    },
    onDestroy(id) {
      const Repo = RepositoryFactory.get(this.repository);
      Repo.destroy(id)
        .then(() => {
          // console.debug("successfully deleted!");
          this.$refs[this.tableRef].fetch();
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        });
    },
    openPdfModal() {
      this.$bvModal.show("submitReportModal");
    },
    onSearch(name) {
      let criteria = this.filter;
      this.saveFilterByName({ name, criteria });
      this.exportUrl = this.$refs[this.tableRef].loadFilter(
        this.$refs[this.tableRef].filterName
      );
      // quicksearch
      criteria = {};
      [
        /*"quickSearchPolicy"*/ "quickSearchRegistry",
        "quickSearchGroup",
      ].forEach((element) => {
        criteria[element] = {
          label: "",
          formLabel: "",
          data: null,
          formData: null,
        };
      });
      // criteria.quickSearchPolicy.label = this.form.inpt_label_policy;
      // criteria.quickSearchPolicy.formLabel = "form.inpt_label_policy";
      // criteria.quickSearchPolicy.data = this.insurance_policy_data;
      // criteria.quickSearchPolicy.formData = "insurance_policy_data";
      criteria.quickSearchRegistry.label = this.form.inpt_label_registry;
      criteria.quickSearchRegistry.formLabel = "form.inpt_label_registry";
      criteria.quickSearchRegistry.data = this.registry_data;
      criteria.quickSearchRegistry.formData = "registry_data";
      criteria.quickSearchGroup.label = this.form.inpt_label_group;
      criteria.quickSearchGroup.formLabel = "form.inpt_label_group";
      criteria.quickSearchGroup.data = this.group_data;
      criteria.quickSearchGroup.formData = "group_data";
      this.saveQuickSearchByName({ name, criteria });
      this.removePaginationByName(name);
      this.$refs[this.tableRef].currentPage = 1;
      this.$refs[this.tableRef].fetch();
    },
    onClearFilter(name) {
      this.resetRegistryId();
      this.resetGroupId();
      //this.resetPolicyId();
      this.resetFilter();
      // this.resetQuickSearch(); // dovrebbero bastare reset*id()
      this.resetPagination();
      this.removeFilterByName(name);
      this.removeQuickSearchByName(name);
      this.removePaginationByName(name);
      this.removeSortByName(name);
      // this.getLastCreated();
      this.getLastCreated()
        .then(() => {
          // se non c'è già un filtro salvato, imposto i valori di default a byCalendar
          // let found = this.loadFilter(this.filterName);
          // if (!found) {
          this.filter.byCalendar.from = this.lastCreated;
          this.filter.byCalendar.to = this.lastCreated;
          // }
          // this.formLoaded = true;
        })
        .catch(() => {});
      let criteria = this.filter;
      this.saveFilterByName({ name, criteria });
      this.$refs[this.tableRef].sortBy = "book_date";
      this.$refs[this.tableRef].sortDesc = false;
      // this.onSearch(name);
    },
    getLastCreated() {
      const Repo = RepositoryFactory.get("book_entry");
      this.isFilterLoading = true;
      return Repo.index("byLastCreated&byView=book_entry")
        .then((response) => {
          this.lastCreated =
            response.data && response.data.data.length
              ? response.data.data[0].last_created
              : null;
          // this.filter.byCalendar.from = this.lastCreated;
          // this.filter.byCalendar.to = this.lastCreated;
          this.isFilterLoading = false;
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
          this.isFilterLoading = false;
        });
    },
    ...mapActions("filters", {
      saveFilterByName: "saveByName",
      removeFilterByName: "removeByName",
    }),
    ...mapActions("quicksearch", {
      saveQuickSearchByName: "saveByName",
      removeQuickSearchByName: "removeByName",
    }),
    ...mapActions("pagination", { removePaginationByName: "removeByName" }),
    ...mapActions("sorts", {
      removeSortByName: "removeByName",
    }),
    onSaveFilter(name, criteria) {
      this.saveFilterByName({ name, criteria });
    },
    ...mapGetters("auth", {
      getInsurers: "insurers",
      getBranches: "branches",
      getRisks: "risks",
      getProducts: "products",
      getSalesmen: "salesmen",
      getCooperators: "cooperators",
      getTreasuries: "treasuries",
      getBookTags: "book_tags",
    }),
  },
  mounted() {
    this.companies = this.getInsurers();
    // this.cooperators = this.getCooperators();
    this.risk_branches = this.getBranches()(this.filter.byInsurer.id);
    this.risks = this.getRisks();
    this.salesmen = this.getSalesmen();
    this.treasuries = this.getTreasuries()([]);
    // this.book_tags_opt = this.getBookTags();
  },
  beforeMount() {
    const path = "auth/";
    const resources = [];
    if (!this.hasLoadedResource("mandate_codes"))
      resources.push(
        this.$store
          .dispatch(`${path}mandate_codes`)
          .then(() => {})
          .catch((error) => {
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({ preset: "error", text: errMsg });
          })
      );
    if (!this.hasLoadedResource("book_tags")) {
      resources.push(
        this.$store
          .dispatch(`${path}book_tags`)
          .then(() => {})
          .catch((error) => {
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({ preset: "error", text: errMsg });
          })
      );
    } else {
      this.book_tags_opt = this.getBookTags();
    }
    if (!this.hasLoadedResource("cooperators")) {
      resources.push(
        this.$store
          .dispatch(`${path}cooperators`)
          .then(() => {})
          .catch((error) => {
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({ preset: "error", text: errMsg });
          })
      );
    } else {
      this.cooperators = this.getCooperators();
    }
    if (!this.hasLoadedResource("agency_brokers"))
      resources.push(
        this.$store
          .dispatch(`${path}agency_brokers`)
          .then(() => {})
          .catch((error) => {
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({ preset: "error", text: errMsg });
          })
      );
    if (resources.length) {
      this.getResources(resources)
        .then(() => {
          this.cooperators = this.getCooperators();
          this.book_tags_opt = this.getBookTags();
          this.isLoading = false;
        })
        .catch((error) => {
          console.log("One or more promises crashed... :|");
          this.onFailure(error);
        })
        .finally(() => {
          // this.unloadMsg();
          this.isLoading = false;
        });
    } else {
      this.isLoading = false;
    }
    this.getLastCreated()
      .then(() => {
        // se non c'è già un filtro salvato, imposto i valori di default a byCalendar
        let found = this.loadFilter(this.filterName);
        if (!found) {
          this.$set(this, "filter", this.initFilter());
          this.filter.byCalendar.from = this.lastCreated;
          this.filter.byCalendar.to = this.lastCreated;
          // this.$set(this.filter.byCalendar, "from", this.lastCreated);
          // this.$set(this.filter.byCalendar, "to", this.lastCreated);
        } else {
          this.$set(this, "filter", found);
        }
      })
      .catch(() => {});
  },
  beforeDestroy() {
    this.onClearFilter(this.filterName);
    /* Fix navigazione all'interno del modulo CONT */
    const filterName = "filterRegistryGroup";
    this.removeFilterByName(filterName);
    this.removePaginationByName(filterName);
    this.removeSortByName(filterName);
  },
  computed: {
    agencyHasFee() {
      return this.$store.state.auth.use_fee === "Y" ? true : false;
    },
  },
  created() {
    if (this.agencyHasFee) {
      const feeGrossField = {
        key: "fee_gross",
        label: this.getDictionary("fee_gross", "book_entry"),
        formatter: (value) => toLocaleCurrency(value),
        sortable: true,
        thClass: "text-right",
        thStyle: {
          "text-align": "right",
        },
        tdClass: "text-right",
      };
      this.fields.splice(9, 0, feeGrossField);
    }
    /* Commented out because of the import of quickSearchRegistry */
    /* */
    // console.log("beforeCreate index");
    // const path = "auth/";
    // const resources = [];
    // if (!this.hasLoadedResource("councils"))
    //   // this.getResources("auth/councils");
    //   // resources.push({
    //   //   path: path,
    //   //   value: "councils",
    //   // });
    //   resources.push(
    //     this.$store
    //       .dispatch(`${path}councils`)
    //       .then(() => {
    //         console.info(`background data "${path}councils" fetched correctly`);
    //       })
    //       .catch((error) => {
    //         let errMsg = this.$getErrorMessage(error);
    //         this.$showSnackbar({ preset: "error", text: errMsg });
    //       })
    //     // .finally(() => {
    //     //   this.unloadMsg();
    //     //   this.isLoading = false;
    //     // })
    //   );
    // if (!this.hasLoadedResource("provinces"))
    //   // this.getResources("auth/provinces");
    //   // resources.push({
    //   //   path: path,
    //   //   value: "provinces",
    //   // });
    //   resources.push(
    //     this.$store
    //       .dispatch(`${path}provinces`)
    //       .then(() => {
    //         console.info(
    //           `background data "${path}provinces" fetched correctly`
    //         );
    //       })
    //       .catch((error) => {
    //         let errMsg = this.$getErrorMessage(error);
    //         this.$showSnackbar({ preset: "error", text: errMsg });
    //       })
    //     // .finally(() => {
    //     //   this.unloadMsg();
    //     //   this.isLoading = false;
    //     // })
    //   );
    // if (!this.hasLoadedResource("countries")) {
    //   resources.push(
    //     this.$store
    //       .dispatch(`${path}countries`)
    //       .then(() => {
    //         console.info(
    //           `background data "${path}countries" fetched correctly`
    //         );
    //         // const defaultCountryId = this.getCountries().find(
    //         //   (country) => country.text == this.defaultCountryName
    //         // ).value;
    //         // this.opt_provinces = this.getProvinces()(defaultCountryId);
    //       })
    //       .catch((error) => {
    //         let errMsg = this.$getErrorMessage(error);
    //         this.$showSnackbar({ preset: "error", text: errMsg });
    //       })
    //     // .finally(() => {
    //     //   this.unloadMsg();
    //     //   this.isLoading = false;
    //     // })
    //   );
    // }
    // if (resources.length) {
    //   this.getResources(resources)
    //     .then(() => {
    //       console.log("All promises went through correctly");
    //     })
    //     .catch((error) => {
    //       console.log("One or more promises crashed... :|");
    //       this.onFailure(error);
    //     })
    //     .finally(() => {
    //       this.isLoading = false;
    //       // this.$emit("storageLoading", false);
    //     });
    // } else {
    //   this.isLoading = false;
    //   // this.$emit("storageLoading", false);
    // }
    /* */
  },
};
</script>
